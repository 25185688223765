import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { FlexRow } from "@/components/grid/extensions";
import { ContentfulNextImage } from "@/components/image";
import { Typography } from "@/components/typography/typography";
import { VisualContent, BackgroundOverlay } from "@/components/visual/components";
import type { Layout3Props } from "@/components/visual/types";
import { TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";
import { AssetFit, AssetFocus } from "@/types/contentful-images";
import { staticHeaderImageLoader } from "@/utils/image";

import { Spacer } from "../layout/components";

const VisualText = styled.div<PropsWithTheme>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			justify-content: center;
		}
	`};
`;

const Wrapper = styled.div<PropsWithTheme & { large?: boolean }>`
	display: flex;
	position: relative;
	width: 100%;
	${({ theme: { palette, mq }, large }) => css`
		background-color: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
		@media ${mq.l} {
			height: calc(100vh - var(--header-height) - var(--spacing-xxl));
			max-height: ${large ? "900px" : "600px"};
		}
	`};
`;

export const Layout3 = ({ data, children, large, removeBottomSpacing }: Layout3Props) => {
	const { featuredImage, featuredImageMobile, headline, subtitle } = data;

	return (
		<>
			<Wrapper large={large}>
				{featuredImage && !featuredImageMobile && (
					<ContentfulNextImage
						priority
						src={featuredImage.url}
						alt={featuredImage.description}
						f={AssetFocus.top}
						fit={AssetFit.fill}
						layout="fill"
						objectFit="cover"
					/>
				)}
				{featuredImageMobile && featuredImageMobile && (
					<>
						<Hidden m l>
							<ContentfulNextImage
								priority
								src={featuredImage.url}
								alt={featuredImage.description}
								f={AssetFocus.top}
								fit={AssetFit.fill}
								layout="fill"
								objectFit="cover"
							/>
						</Hidden>
						<Hidden s>
							<ContentfulNextImage
								priority
								loader={staticHeaderImageLoader}
								src={featuredImageMobile.url}
								alt={featuredImageMobile.description}
								quality={50}
								layout="fill"
								objectFit="cover"
								f={AssetFocus.top}
								fit={AssetFit.fill}
							/>
						</Hidden>
					</>
				)}
				<BackgroundOverlay />
				<Grid overflow>
					<Row>
						<Column flex l={7}>
							<VisualContent>
								<FlexRow>
									<Column flex l={6}>
										<VisualText>
											<Typography
												component="h1"
												tight={Boolean(subtitle)}
												variant={TypographyVariant.headlineSerif2XL}
											>
												<BreakLines text={headline} />
											</Typography>
											{subtitle && (
												<Typography>
													<BreakLines text={subtitle} />
												</Typography>
											)}
											{children}
										</VisualText>
									</Column>
								</FlexRow>
							</VisualContent>
						</Column>
					</Row>
				</Grid>
			</Wrapper>
			{!removeBottomSpacing && <Spacer spacing="m" />}
		</>
	);
};
